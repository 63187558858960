import { render, staticRenderFns } from "./projectModal.vue?vue&type=template&id=2a155a79&scoped=true&"
import script from "./projectModal.vue?vue&type=script&lang=js&"
export * from "./projectModal.vue?vue&type=script&lang=js&"
import style0 from "./projectModal.vue?vue&type=style&index=0&id=2a155a79&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a155a79",
  null
  
)

export default component.exports