<template>
  <div class="home">
	  <div class="mianbao">
	  	<div class="breadcrumb">
	  		<span style="color: #fff;">
	  			系统设置>
	  		</span>
	  		<span style="color:#016ae0;">
	  			项目管理
	  		</span>
	  	</div>
	  </div>
    <div class="header">
      <a-input class="input" v-model="projectName" placeholder="请输入项目名称"/>
      <a-button type="primary" icon="search" @click="getProjectList">查询</a-button>
      <a-divider type="vertical"/>
      <a-button type="primary" icon="plus" @click="showModal">新增项目</a-button>
    </div>
    <!--    新增-->
    <projectModal
        :disabled="disabled"
        @showModal="getData"
    >
    </projectModal>
    <!--      修改-->

    <projectChange
        :is_show="is_show"
        :oneProjectData="oneProjectData"
        @changeModal="changeModal"
    ></projectChange>
    <div class="main">
    <vue-scroll :ops="ops" style="width:100%;">
		  <Table :list="data" />
    </vue-scroll>
    </div>
  </div>
</template>

<script>
import projectModal from "./modal/projectModal";
import projectChange from "./modal/projectChange";
import Table from './modal/table.vue'
export default {
   inject: ['reload'],
  name: "project",
  data() {
    return {
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: "#F5F5F5",//滚动条颜色
          opacity: 0.5,//滚动条透明度
          "overflow-y": "hidden"
        }
      },
      disabled: false,
      data: [],
      columns: [
        {
          title: '项目名称',
          dataIndex: 'project_name',
		  scopedSlots: {customRender: 'project_name'},
        },
        {
          title: '工作组',
          dataIndex: 'group_list',
          scopedSlots: {customRender: 'group_list'},
        },
        {
          title: '状态',
          dataIndex: 'state',
        },
        {
          title: '创建时间',
          dataIndex: 'create_time',
          scopedSlots: {customRender: 'create_time'},
        },
        {
          title: '操作',
          scopedSlots: {customRender: 'action'},
          key: 'action',
          width: 400
        },
      ],
      is_show: false,
      oneProjectData: {},
      projectName: '',
      projectIdList: sessionStorage.getItem('projectId'), //根据项目id获取数据
    }
  },
  components: {
    projectModal,
    projectChange,
	Table
  },
  mounted() {
    this.projectIdList = sessionStorage.getItem('projectId')
    this.getProjectList()
  },
  methods: {
    //修改
    showChangeModal(record) {
      this.is_show = true
      let a = record.group_list.map(item=>item.group_id)
      let b =[ record.small_place.province_code , record.small_place.city_code ,record.small_place.area_code]
      this.oneProjectData = record
      this.oneProjectData.group_list = a
      this.oneProjectData.area = b
      console.log(this.oneProjectData);
    },
    changeModal(data) {
      this.is_show = data
      this.getProjectList()
    },
    //增加
    showModal() {
      this.disabled = true
    },
    getData(data) {
      this.disabled = data
      this.getProjectList()
    },
    // 查询项目管理列表
    getProjectList() {
      let projectName = this.projectName
      this.$axios.get(this.api+`/project/list?projectIdList=${this.projectIdList}`, {
        params: {
          projectName
        }
      }).then(
          res => {
            if (res.code === 200) {
              this.data = res.data.list
            }
          }
      )
    },
  }
}
</script>


<style scoped>
	div /deep/.header>.ant-input{
		color: #fff;
		background-color: rgba(7, 23, 46, 1.0);
	}
.home {
  height: 100%;
  padding: 15px;
 background: url(../../assets/bg01.jpg) no-repeat;
 background-size: 100% 100%;
}
	.mianbao{
		height:40px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		/* background-color: yellow; */
	}
.header {
  width: 100%;
  /* background: #fff; */
  height: 60px;
  text-align: left;
  padding: 15px;
  border: 1px solid #016ae0;
  border-radius: 8px;
}

.main {
  height: calc(100% - 120px);
  /* background: #fff; */
   border: 1px solid #016ae0;
   border-radius: 8px;
  margin-top: 20px;
  padding: 10px;
}

.input {
  width: 180px;
  margin-right: 20px;
}
</style>
