<template>
	<a-modal
		title="修改项目"
		:visible="is_show"
		:confirm-loading="confirmLoading"
		:maskClosable="false"
		okText="提交"
		cancelText="取消"
		@ok="handleOk"
		@cancel="handleCancel"
	>
		<div class="oneDiv">
			<p>项目名称：</p>
			<a-input
				class="input"
				placeholder="请输入项目名称"
				v-model="oneProjectData.projectName"
			/>
		</div>
		<div class="oneDiv">
			<p>项目工作组：</p>
			<a-select
				mode="multiple"
				v-model="ultimately"
        placeholder="请选择工作组"
				class="input"
				@change="handleChange"
			>
				<a-select-option
					:value="worker.groupId"
					v-for="(worker, index) in workerList"
					:key="index"
				>
					{{ worker.groupName }}
				</a-select-option>
			</a-select>
		</div>
		<!-- <div class="oneDiv">
			<p>地区：</p>
			<a-cascader
				:options="options"
				change-on-select
				@change="onChange"
        placeholder="请选择地区"
				:default-value="oneProjectData.area"
				class="input"
			/>
		</div> -->
		<div class="oneDiv">
			<p>项目描述：</p>
			<a-textarea
				class="input"
				v-model="oneProjectData.projectIntroduction"
				placeholder="请输入项目描述"
				:auto-size="{ minRows: 3, maxRows: 5 }"
			/>
		</div>
	</a-modal>
</template>

<script>
export default {
	inject: ['reload'],
	data() {
		return {
			confirmLoading: false,
			formData: {},
			options: [],
			workerList: [],
			chooseList: [],
			groupIdList: sessionStorage.getItem('groupId'),
		}
	},
	props: ['is_show', 'oneProjectData', 'ultimately'],
	mounted() {
		this.getArea()
		this.getWorker()
	},
	methods: {
		handleOk() {
			console.log(this.oneProjectData)
			this.formData.projectName = this.oneProjectData.projectName
			this.formData.projectIntroduction =
				this.oneProjectData.projectIntroduction
			this.formData.orgId = this.oneProjectData.orgId
			this.formData.groupIdList = this.oneProjectData.groupIdList
			this.formData.projectId = this.oneProjectData.projectId
			this.$axios
				.put(this.api + '/project/update', this.formData)
				.then((res) => {
					if (res.code === 200) {
						this.$emit('changeModal', false)
						this.$message.success('修改成功')
						this.formData = {}
						this.reload()
					} else {
						this.$message.error(res.message)
					}
				})
		},
		handleCancel(e) {
			this.$emit('changeModal', false)
			this.formData = {}
		},
		onChange(label) {
			this.formData.org_id = label.slice(-1)[0]
		},
		// 获取地区
		getArea() {
			this.$axios.get(this.api + '/project/search').then((res) => {
				if (res.code === 200) {
					this.options = this.listToTree(res.data)
				}
			})
		},
		listToTree(list, parentId = '') {
			return list
				.filter((item) => item.parentId === parentId)
				.map((item) => ({
					...item,
					label: item.orgName,
					value: item.id,
					children: this.listToTree(list, item.id),
				}))
		},
		//查询工作组
		getWorker() {
			this.$axios
				.get(this.api + `/group/list?groupIdList=${this.groupIdList}`)
				.then((res) => {
					if (res.code === 200) {
						this.workerList = res.data.list
					}
				})
		},
		handleChange(value) {
			// console.log(`selected ${value}`);
			this.oneProjectData.groupIdList = value
			this.formData.groupIdList = value
		},
	},
}
</script>

<style scoped>
.input {
	width: 70% !important;
}
.oneDiv {
	display: flex;
	font-size: 14px;
	margin: 15px;
}
.oneDiv > p {
	width: 100px;
}
</style>
