<template>
	<div class="table">
		<div class="header">
		<!-- 	<div class="duoxuan">
				<div class=""></div>
			</div> -->
			<div class="list">
				<div class="item" :style="{'width':item.width+'%'}" v-for="(item, index) in columns" :key="index">
					{{item.title}}
				</div>
			</div>
		</div>
		<div class="table_body">
			<div class="body" v-for="(item, index) in list" :key="index">
				<div class="list">
					<div class="item" style="width:20%;">
						{{item.projectName}}
					</div>
					<div class="item" style="width:20% ;">
						<a-tag color="blue" v-for="item1 in item.groupList" :key="item1.groupId">
						  {{ item1.groupName }}
						</a-tag>
					</div>
					<div class="item" style="width:20% ;">
						{{item.projectState}}
					</div>
					<div class="item" style="width:20% ;">
						 {{ item.createTime}}
					</div>
					<div class="item" style="width:20% ;">
						<a-button type="primary" @click="handleSee(item)">查看</a-button>
						<a-button type="primary" style="margin: 0 20px;" @click="showChangeModal(item)">修改</a-button>
            <a-popconfirm
              title="确认删除?"
              ok-text="确认"
              cancel-text="取消"
              @confirm="deleteData(item.projectId)"
              @cancel="cancel"
            >
						<a-button type="danger">删除</a-button>
            </a-popconfirm>
					</div>
					
				</div>
				
			</div>
      <!-- 查看 -->
			   <a-modal
			      :title="Title"
			      :visible="visible"
			      @cancel="handleCancel"
				  :footer="false"
			    >
			     <div class="model_item">
			     	<div class="label">
			     		项目全称：
			     	</div>
					{{form.fullName}}
			     </div>
				 <div class="model_item">
				 	<div class="label">
				 		项目编号：
				 	</div>
				 		{{form.projectNum}}
				 </div>
				 <div class="model_item">
				 	<img style="height:400px;" :src="'http://124.128.96.67:18083'+form.projectImg" >
				 </div>
			    </a-modal>
          <!-- 修改 -->
          <!-- 修改菜单 -->
    <projectChange
        :is_show="is_show"
        :oneProjectData="oneProjectData"
        @changeModal="changeModal"
        :ultimately="ultimately"
    ></projectChange>
			
		</div>
	</div>
</template>

<script>
import projectChange from "./projectChange";

	export default{
		props:{
			list:Array
		},
    components:{
      projectChange,
  },
  inject: ['reload'],
		data(){
			return {
				Title:'',
				visible:false,
				form:{},
				columns:[
				  {
					   title: '项目名称',
				    dataIndex: 'projectName',
				    key: 'projectName',
					width:'20'
				  },
				  {
				    title: '工作组',
				    dataIndex: 'age',
				    key: 'age',
					width:'20'
				  },
				  {
				    title: '状态',
				    dataIndex: 'address',
				    key: 'address',
					width:'20'
				  },
				  {
				    title: '创建时间',
				    key: 'tags',
				    dataIndex: 'tags',
					width:'20'
				  },
				  {
				    title: '操作',
				    key: 'action',
					  dataIndex: 'tags',
					  width:'20'
				  },
				
				],
			oneProjectData: {},
      is_show:false,
      aaa: [],
      ultimately: []
			}
		},
		methods:{
			handleDetail(){
				this.$router.push('/except/detail')
			},
			handleCancel(){
				this.visible=false
			},
      // 修改
			showChangeModal(record){
        this.aaa = []
        this.oneProjectData = record
				this.is_show = true
        record.groupList.forEach(item => {
          this.aaa.push(item.groupId)
        })
        //   // 数组去重
        // let add = this.aaa.filter(function(item, index, self) {
        //     return self.indexOf(item) == index;
        //   })
          this.ultimately = this.aaa
			},
      changeModal(){
        this.is_show = false
    },
    // 删除项目
    deleteData(projectId) {
      this.$axios.delete(this.api+'/project/delete/' + projectId )
      .then(res => {
        if (res.code === 200) {
          this.$message.success('删除成功')
          this.reload()
        }
      })
    },
    // 取消删除
      cancel() {
        this.$message.warning('已取消删除')
      },
    // 查看
			handleSee(record){
				this.Title=record.projectName
				this.visible=true
				this.form=record
				
			}
		}
	}
</script>

<style scoped>
	.table{
		width: 100%;
		height: 100%;
		color: #fff;
		/* background-color: red; */
	}
	.yuan{
		width: 5px;
		height: 5px;
		border-radius: 50%;
		background-color: red;
		margin-right: 5px;
	}
	.item1>div{
		display: flex;
	}
	.yi>div,
	.wei>div{
		margin-right:10px;
		width: 4em;
	}
	.wei>div:hover{
		cursor: pointer;
	}
	.duoxuan{
		width: 40px;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		
	}
	.duoxuan>div{
		width: 10px;
		height: 10px;
		border: 1px solid #ccc;
		background-color: #fff;
	}
	.header{
		display:flex;
		height: 50px;
		background-color:#1f325e ;
		border-bottom: 1px solid #005ea1;
		/* align-items: center; */
	}
	.header .list{
		background-color:#1f325e ;
		width: calc(100% - 40px);
		display: flex;
		align-items: center;
	}
	.table_body{
		height: calc(100% - 50px);
		overflow-y: auto;
	}
	.table_body .body{
		display:flex;
		min-height: 50px;
		border-bottom: 1px solid #005ea1;
		/* align-items: center; */
	}
	.table_body .list{
		/* background-color:#1f325e ; */
		width: calc(100% - 40px);
		display: flex;
		align-items: center;
	}
	.item{
		display: flex;
		align-items: center;
	    flex-wrap: wrap;
		padding: 0 20px;
	}
	.model_item{
		display: flex;
		line-height: 3em;
		
	}
	.ant-tag-blue {
		margin: 8px 2px;
	}
/* 	.model_item1{
		width:100%;
		justify-content: center;
	} */
</style>
